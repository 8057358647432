import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { store } from '~/pages/heineken_template/_private/store';
/** 近月遠月分數顏色 */
const monthScoreFill = (value) => {
    if (value > 0) {
        return '#6490c4';
    }
    else if (value < 0) {
        return '#f60013';
    }
    else
        return '#efefef';
};
/** 總分柱狀圖顏色 */
const barFill = (value) => {
    if (value > 0) {
        return 'linear-gradient(100deg, #cc272f, #fb5656)';
    }
    else if (value < 0) {
        return 'linear-gradient(100deg,  #74d615, #acff66bb)';
    }
    else
        return 'linear-gradient(100deg,  #aaaaaa, #aaaaaa)';
};
/** 總分欄位顏色 */
const totalScoreFill = (value, rankValue) => {
    /** 假設最大數值 越大漸層越明顯 */
    const maxValue = rankValue;
    /** max:255 min:0 */
    const range = 232;
    /** 如果加總超過maxValue 以maxValue替代 */
    const value_ = Math.abs(value) >= maxValue ? maxValue : Math.abs(value);
    const base = Math.abs((value_ / maxValue) * 180);
    const red = value > 0 ? range : range - base;
    const green = value < 0 ? range : range - base;
    const blue = Math.abs(232 - base);
    return `rgb(${Math.abs(red)}, ${Math.abs(green).toFixed(0)}, ${Math.abs(blue)})`;
};
export const StockScoreTableHeader = memo(function StockScoreTableHeader() {
    return (<styleds.header>
      <p>商品名稱</p>
      <p>近月</p>
      <p>分數</p>
      <p>遠月</p>
      <p>分數</p>
    </styleds.header>);
});
export const StockScoreTable = memo(function StockScoreTable(props) {
    const data = props.data;
    const max = Math.max(...data.map(s => Math.abs(s.total)));
    const charting = useSnapshot(store.charting);
    const symbolNumber = charting.symbol;
    return (<styleds.container>
      {data.length === 0 && <div>目前沒有商品</div>}
      {data.map((item, index) => {
            /** 各自總分佔全部的百分比 */
            const ratio = (Math.abs(item.total) / max) * 100;
            /** 柱狀圖長度 (小於5用5代替 才看得到柱狀圖) */
            const barWidth = ratio > 5 ? ratio.toFixed(2) : '5';
            /** 選擇狀態 */
            const isSelect = item.name === symbolNumber;
            return (<styleds.itemContent key={index} isSelect={isSelect} onClick={event => {
                    props.changeSymbol === true && store.charting.changeSymbol(item.name);
                    props.onClick?.();
                    props.changeSymbol === true && store.charting.updateFromState();
                }}>
            <div>{fr_instrument.getName(item.name.toString())}</div>
            <styleds.monthScore fill={monthScoreFill(Number(item.near_month))}>
              {Number(item.near_month)}
            </styleds.monthScore>
            <styleds.totalScore fill={totalScoreFill(item.total, props.rank)}>
              {Number(item.near_month) + Number(item.next_month)}
            </styleds.totalScore>
            <styleds.monthScore fill={monthScoreFill(Number(item.next_month))}>
              {Number(item.next_month)}
            </styleds.monthScore>
            <styleds.barContent>
              <styleds.bar width={barWidth} fill={barFill(item.total)}>
                &nbsp;
              </styleds.bar>
            </styleds.barContent>
          </styleds.itemContent>);
        })}
    </styleds.container>);
});
const styleds = {
    header: styled.div `
    display: grid;
    grid-template-columns: 37% 16% 16% 16% 15%;
    width: 100%;
    > p {
      background-color: #454545;
      ${fill_vertical_all_center};
      height: 32px;
      margin: 0;
      font-size: 14.8px;
    }
  `,
    container: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    font-size: 14px;
    border-radius: 5px;
    padding: 2px;
    background-color: #1f1f1f;
  `,
    //
    itemContent: styled.div `
    ${fill_vertical_all_center};
    display: grid;
    grid-template-columns: 37% 16% 16% 16% 15%;
    width: 100%;
    height: 28px;
    cursor: pointer;
    background-color: ${props => (props.isSelect === true ? '#2a2a2a' : '')};
    &:hover {
      background-color: #2f2f2f;
    }
    > div {
      ${fill_horizontal_all_center};
      height: 26.8px;
    }
  `,
    totalScore: styled.div `
    height: 22px !important;
    background: linear-gradient(100deg, ${props => props.fill}, ${props => props.fill});
    color: #252525;
  `,
    monthScore: styled.div `
    color: ${props => props.fill};
    background-color: #353535;
    border-radius: 3px;
    height: 22px !important;
  `,
    barContent: styled.div `
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: start !important;
  `,
    bar: styled.div `
    border-left: 2px solid #252525;
    width: ${props => props.width}%;
    background: ${props => props.fill};
    border-radius: 3px;
  `,
};
