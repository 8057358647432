import styled from '@emotion/styled';
import { Tooltip } from '@mantine/core';
import { memo } from 'react';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
export const PocketBrokerOpenAccountLogo = memo(function PocketBrokerOpenAccountLogo() {
    return (<classes.container href={'https://www.pocket.tw/openaccountonline/oa/login/new?mkCode=AAE104&channel=CH0001&openExternalBrowser=1&next=TU'} target='_blank' rel='noreferrer'>
      <Tooltip label='點擊進入口袋證券線上開戶'>
        <classes.logo src='pocket/pocket.png'/>
      </Tooltip>
    </classes.container>);
});
const classes = {
    container: styled.a `
    width: 192px;
  `,
    logo: styled.img `
    ${fill_horizontal_all_center};
    border-radius: 2px;
    cursor: pointer;
  `,
};
