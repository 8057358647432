import styled from '@emotion/styled';
import { Avatar } from '@mantine/core';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { ENV } from '~/configs/ENV';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import dayAPI from '~/utils/dayAPI';
/** 需要抽出當共用funtcion */
const getMatchedSubscription = (subscriptions, agentName, // target name
product) => {
    for (const subscription of subscriptions) {
        if (subscription.agentName === agentName && subscription.productName === product) {
            return subscription;
        }
    }
    return null;
};
export const OpkevinLogOutView = memo(function OpkevinLogOutView() {
    const firebaseMe = useFirebaseMeState.useContainer();
    const { meUserState } = useMeStore();
    /** 產品名稱 */
    const agentName = useMeStore(state => state.agentName);
    /** product名稱 */
    const produtName = useMeStore(state => state.product);
    /** 會員名稱 */
    const userName = meUserState?.name ?? '-';
    /** 會員代碼 */
    const userNumber = useMeStore(state => state.meUserState?.code);
    /** 會員信箱 */
    const email = useMeStore(state => state.meUserState?.email);
    const photoUrl = useMeStore(state => state.meUserState?.photo);
    const commitHash = ENV.COMMITHASH.slice(-5);
    const commitDate = ENV.VERSION.slice(-5).replace('-', '');
    /** commit + MMDD 方便查找commit與部屬日 */
    const virson = commitHash + '-' + commitDate;
    /** 會員大頭貼 */
    const userPicture = (<Avatar src={photoUrl} size={48} radius='lg' alt="it's me"/>);
    let matchedSubscription = null;
    if (meUserState?.subscriptions) {
        matchedSubscription = getMatchedSubscription(meUserState?.subscriptions, agentName, produtName);
    }
    /** 到期日期 */
    const expDate = matchedSubscription && dayAPI(matchedSubscription.expiredAt).format('YYYY-MM-DD');
    const nowDate = dayAPI().format('YYYY-MM-DD');
    return (<styleds.container>
      <styleds.topContent>
        <p>{userPicture}</p>
        <p>{userName}</p>
        <p>{email}</p>
      </styleds.topContent>
      <styleds.infoContent>
        <p>會員代碼 {userNumber}</p>
        {/* <p>產品名稱 {agentName}</p> */}
        <p>群組名稱 {produtName.replace('web_', '')}</p>
        <p>到期日期 {expDate ?? '體驗中'}</p>
        <p>版本編號 {virson}</p>
        <styleds.logoutButton onClick={() => firebaseMe.acts.logout()}>登出</styleds.logoutButton>
      </styleds.infoContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    width: 280px;
    height: 360px;
    border-radius: 10px;
    gap: 16px;
    background: linear-gradient(45deg, #25262b, #15161e);
    color: #efefef;
  `,
    topContent: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 124px;
    padding: 8px;
    background-image: linear-gradient(90deg, #191946, #191946cc, #19194655),
      url('opkevin/background-small.jpg');
    background-size: 100%;
    background-position: 50% 100%;
    border-radius: 10px 10px 0 0;
    & > p {
      padding: 4px;
      font-size: 14px;
      line-height: 14px;
      margin: 0px;
    }
  `,
    infoContent: styled.div `
    ${fill_vertical_cross_center};
    height: 236px;
    gap: 4px;
    & > p {
      width: 186px;
      padding: 12px;
      font-size: 16px;
      line-height: 16px;
      margin: 0px;
      border-radius: 5px;
      transition: 0.3s;
      &:hover {
        font-size: 16.8px;
        background: linear-gradient(45deg, #25262b00, #25262b, #25262b00);
        transition: 0.3s;
      }
    }
  `,
    logoutButton: styled.div `
    ${fill_horizontal_all_center};
    width: 240px;
    height: 32px;
    border-radius: 6px;
    background-color: #9b4137;

    &:hover {
      background-color: #9b4137aa;
      cursor: pointer;
    }
  `,
};
